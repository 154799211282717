import axios from '@axios'
import qs from 'qs'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    search(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/news/search', {params: params})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    view(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/news/view', {params: params})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    edit(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .get('/api/news/edit', {params: params})
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    save(ctx, params) {
      axios({
        url:'/api/news/save',
        method:'post',
        params: {
          newsId:params.id,
          title:params.title,
          content:params.content,
          timeLimit:params.time_limit,
          isTop:params.is_top != null ? params.is_top : 0,
          isMust:params.is_must != null ? params.is_must : 0,
          platform:params.platform != null ? params.platform.value : 1,  //默认是pc
          attachments:params.attachments,
        },
      })
    },
    state(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('/api/news/state', params)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
  },
}
