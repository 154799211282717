<template>

  <div>

    <!-- Table Container Card -->
    <b-card
        no-body
        class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>显示</label>
            <v-select
                v-model="limit"
                :options="limitOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
            />
            <label>条</label>
          </b-col>

          <!-- Search -->
          <b-col
              cols="12"
              md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                  v-model="searchKey"
                  class="d-inline-block mr-1"
                  placeholder="搜索关键字..."
              />
              <b-button
                  variant="primary"
                  :to="{ name: 'apps-news-edit'}"
              >
                <span class="text-nowrap">添加</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
          ref="refListTable"
          class="position-relative"
          :items="searchList"
          responsive
          hover
          small
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="orderBy"
          show-empty
          empty-text="未找到记录"
          :sort-desc.sync="isSortDirDesc"
      >

        <!-- Columns -->
        <template #cell(id)="data">
            #{{ data.item.id }}
        </template>

        <!--  标题      -->
        <template #cell(title)="data" >
          <a @click="showNewsInfo(data.item)">{{ data.item.title }}</a>
        </template>

        <template #cell(is_must)="data">
          <b-badge
              pill
              :variant="`light-${getCodeColor('yesno', data.item.is_must)}`"
          >
            {{ getCodeLabel('yesno', data.item.is_must) }}
          </b-badge>
          <span v-if="data.item.is_must == 1">({{ data.item.time_limit }}秒)</span>
        </template>

        <!--置顶-->
        <template #cell(is_top)="data">
          <feather-icon
              icon="CheckIcon"
              size="21"
              v-if="data.item.is_top==1"
          />
          <feather-icon
              icon="XIcon"
              size="21"
              v-else
          />
        </template>

        <!--启用-->
        <template #cell(state)="data">
          <b-badge
              pill
              :variant="`light-${getCodeColor('state', data.item.state)}`"
              @click="changeState(data.item)"
          >
            {{ getCodeLabel('state', data.item.state) }}
          </b-badge>
        </template>

        <!-- 添加时间       -->
        <template #cell(create_time)="data">
          {{ toTime(data.item.create_time) }}
        </template>

        <!--添加人        -->
        <template #cell(creator)="{value}">
          {{ getCodeLabel('user', value) }}
        </template>

        <!-- 更新时间       -->
        <template #cell(update_time)="data">
          {{ toTime(data.item.update_time) }}
        </template>

        <!--更新人        -->
        <template #cell(updator)="{value}">
          {{ getCodeLabel('user', value) }}
        </template>

        <!--平台        -->
        <template #cell(platform)="{value}">
          {{ getCodeLabel('news_platform', value) }}
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
              variant="link"
              no-caret
          >

            <template #button-content>
              <!--多菜单-->
              <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
              />
            </template>

            <b-dropdown-item :to="{ name: 'apps-news-edit', query: { id: data.item.id } }">
              <feather-icon icon="EditIcon"/>
              <span class="align-middle ml-50">编辑</span>
            </b-dropdown-item>

          </b-dropdown>
        </template>

      </b-table>

      <b-modal
          id="modal-newsInfo"
          ok-only
          ok-title="确认"
          cancel-title="取消"
          centered
          hide-header
          size="lg"
          ref="newsModal"
      >
        <b-card :header="newsInfo.title">
            <span v-html="newsInfo.content"></span>
        </b-card>
      </b-modal>


      <div class="mx-2 mb-2">
        <b-row>

          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">显示 {{ dataMeta.from }} - {{ dataMeta.to }} / {{ dataMeta.of }} 记录</span>
          </b-col>
          <!-- Pagination -->
          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
                v-model="start"
                :total-rows="listTotals"
                :per-page="limit"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>

  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import {ref, onUnmounted} from '@vue/composition-api'
import {avatarText, toTime, toDate, getCodeOptions, getCode, getCodeLabel, getCodeColor} from '@core/utils/filter'
import newsUseList from './newsUseList'
import newsStore from './newsStore'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
  },
  data() {
    return {
      newsInfo:'',
    }
  },
  methods: {
    changeState: function (data) {

      const state = 1 - data.state
      store.dispatch('news/state', {id: data.id, state: state}).then(res => {
        data.state = state
      })
    },
    showNewsInfo(item){
      this.newsInfo = item
      this.$refs['newsModal'].show()
    },
  },
  setup() {
    // Register module
    if (!store.hasModule('news')) store.registerModule('news', newsStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('news')) store.unregisterModule('news')
    })

    const {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,

      // UI
    } = newsUseList()

    return {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,

      // Filter
      avatarText,
      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
      // UI
      toTime,
      toDate,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
